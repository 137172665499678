import React from 'react'
import Layout from '../components/layout'
import Paragraph from '../components/paragraph'
import H3 from '../components/h3'
import { FormattedMessage } from 'react-intl'
import { withPrefix } from 'gatsby'
import cestapitanjaIcon from '../images/header/cesta_pitanja.svg'
import { Wrapper } from '../components/baseStyles'
import AnimationDropDownList from '../components/animationDropDownList'

import SEO from '../components/seo'

const faqHr = [
	{
		title: 'Koliko traje ugovor s Litto agencijom?',
		paragraph:
			'Ugovor potpisujemo na neodređeno vrijeme, a u slučaju da se predomisliš i želiš samostalno nastaviti s iznajmljivanjem, možeš ga raskinuti u bilo kojem trenutku. Jedini uvjet je da se realiziraju rezervacije koje su pristigle prije raskida ugovora.',
	},
	{
		title:
			'Odlučim li postati Litto partner, smijem li raditi s drugim agencijama?',
		paragraph:
			'Naravno! Imaš priliku primati stalne goste, surađivati s drugim agencijama i voditi svoj sistem rezervacija. Vrlo je važno da nam na vrijeme javljaš zauzetost kako bismo blokirali nedostupne datume i izbjegli probleme sa overbookingom.'
	},
	{
		title:
			'Mogu li samostalno određivati cijene svog smještaja?',
		paragraph:
			'Uvijek ćemo poslušati tvoje stavove, no svakako predlažemo savjetovanje s nekim od naših agenata kako bismo odredili optimalnu cijenu u skladu s kvalitetom smještajne jedinice i tržišnom situacijom.',
	},
	{
		title: 'Trebam li prijavljivati goste u eVisitor i voditi evidenciju prometa?',
		paragraph:
			'Odlučiš li se za Full ili Standard paket, administracija je za tebe briga manje. Sve vezano uz prijavu gostiju, izdavanje računa i vođenje evidencije u potpunosti je naš posao. Svu izdanu dokumentaciju šaljemo ti krajem sezone ili po završetku obračunskog razdoblja.'
	},
	{
		title:
			'Mogu li zatražiti samo uslugu dočekivanja gostiju?',
		paragraph:
			'Osim tri paketa Litto suradnje, dodali smo i jedinstvenu uslugu dočekivanja gostiju u tvome smještaju. <a href="/dogovori-sastanak">Javi nam se</a> za termin i dogovori check-in uslugu.'
	},
	{
		title:
			'Ako gosti žele rezervirati neku dodatnu uslugu, mogu li se obratiti svom Litto agentu?',
		paragraph:
			'Svakako! Gostima želimo omogućiti što ugodniji boravak i upoznati ih s destinacijom kroz zanimljive i kvalitetne sadržaje u destinaciji. U suradnji s našim partnerima osigurali smo širok izbor usluga po povoljnim cijenama za tvoje goste.'
	},
	{
		title: 'Što ako gosti žele produžiti svoj boravak u smještaju?',
		paragraph: 'Traže li gosti produljenje boravka, prvo je potrebno provjeriti raspoloživost u navedenom terminu. Ako su termini slobodni i s gostima dogovoriš produljenje boravka, ne zaboravi nam javiti kako bismo zatvoriti termine u kalendaru.'
	},
	{
		title:
			'Koliki je iznos maksimalne godišnje zarade iznajmljivača koji ne žele ući u porezni sustav?',
		paragraph:
			'Iznajmljivači rezidenti sustavu PDV-a pristupaju nakon ostvarenog prometa od 300.000 kuna ili sustavu mogu pristupiti svojevoljno. No, vodi računa da se u tom slučaju mijenjaju i tvoje obveze. Iznajmljivači nerezidenti obvezni su registrirati se za potrebe PDV-a.',
	},
]
const faqEn = [
	{
		title: 'What’s the standard contract duration?',
		paragraph:
			'The contract is signed for an indefinite period of time. If you decide to rent on your own after all, you can terminate the contract at any time, as soon as we finalize reservations that were already confirmed before the termination date was set into place.',
	},
	{
		title:
			'Am I allowed to work with other agencies?',
		paragraph:
			'We don’t mind it! You are free to host your regulars, cooperate with other agencies and run your own reservation system. We are quite flexible, as long as you inform us in a timely manner so we can block unavailable dates and avoid any inconveniences with our guests.'
	},
	{
		title:
			'Am I still able to independently determine prices for my accommodation?',
		paragraph:
			'Happy to hear your suggestions! We will give give you a few propositions  to help determine the optimal price according to all relevant criteria - location, accommodation quality, local competitors, and so on.'
	},
	{
		title:
			'Can I determine the price of accommodation in my apartment by myself?',
		paragraph:
			'You can, however, we always suggest that you consult with one of our agents to determine the best optimal price and thus get more reservations and more profit.',
	},
	{
		title:
			'Should I still manage the eVisitor system?',
		paragraph:
			'That depends on the type of partnership you sign up for. If you’re in for a BASIC deal, you will still have to report your guests to the eVisitor platform, but in both STANDARD and FULL packages, you can forget about PINs and TANs and passport numbers - we’ve got that covered.'
	},
	{
		title:
			'Can I hire Litto only for checkins and checkouts?',
		paragraph:
			'Indeed, you can. Outside of our usual three partnership deals, we recently added an additional service that includes checking in (and out) your guests on the spot. Click <a href="/en/dogovori-sastanak">here</a> to schedule a meeting and we’ll tell you all about it.'
	},
	{
		title:
			'Neat, are there any more hidden gems in these Litto partnership deals?',
		paragraph: 'As a matter of fact, there are! We want to offer our guests a comfortable stay during their holiday and truly introduce them to the destination. Together with our external partners, we developed a lengthy list of high quality tour operators in Dalmatia, so don’t you dare to worry, your guests are in for a treat!',
	},

	{
		title:
			'What happens if our guests want to extend their stay?',
		paragraph:
			'Well, first of all, you need to let us know and we’ll check the availability in that  period. If there are no clashes with future dates, you can extend their stay.',
	},
	{
		title:
			'What happens if my yearly earnings surpass the 300.000kn annual turnover threshold?',
		paragraph:
			'All renter in Croatia access the VAT regularity after they exceed the annual turnover of 300.000 HRK. If you are a non-resident, and you want to become a renter, then you are required to register for VAT purposes.'
	}
]

const Faq = ({ location, pageContext: { locale } }) => {
	let faq = faqHr
	if (locale === 'en') faq = faqEn
	const isHomePage = location.pathname === withPrefix('/')
	const isFaq = location.pathname.includes('/faq')
	return (
		<Layout
			heroImage={cestapitanjaIcon}
			isFaq={isFaq}
			isHomePage={isHomePage}
			title={
				locale === 'hr'
					? 'Najčešća pitanja naših iznajmljivača'
					: 'Frequently asked questions?'
			}
			text={
				locale === 'hr'
					? 'Imaš pitanje na koje ne pronalaziš konkretan odgovor? Pregledaj  listu pitanja i saznaj zašto smo tvoj idealan partner za sljedeću turističku sezonu.'
					: 'Here’s a basic list of questions (and answers!) that will shed more light on how can Litto contribute to your tourism endavours.'
			}
			locale={locale}
		>
			<SEO
				title={
					locale === 'hr'
						? 'FAQ | Najčešća pitanja naših iznajmljivača | Litto za Partnere'
						: 'FAQ | Frequently Asked Questions | Litto For Partners'
				}
				description={
					locale === 'hr'
						? 'Sve što trebati znati o uvjetima turističke suradnje s Litto agencijom. Trajanje ugovora. Plaćanje. Maksimalna zarada. Mobilne aplikacije. eVisitor.'
						: 'Can\'t find a specific answer to your tourism-related question?Check this page and find out why Litto is your ideal partner for the next tourist season.'
				}
				keywords={['Litto']}
				lang={locale}
			/>
			<Wrapper>
				<AnimationDropDownList options={faq} />
				<div style={{ margin: "60px auto", background: "#eee", padding: "40px", maxWidth: "800px" }}>
					<H3>
						<FormattedMessage id="faqNoteTitle" />
					</H3>
					<Paragraph color="darkBlue">
						<FormattedMessage id="faqNoteText" />
					</Paragraph>
				</div>
			</Wrapper>
		</Layout>
	)
}

export default Faq
